.theme-orange {
  --main-950: #452105;
  --main-900: #80450E;
  --main-800: #A0520C;
  --main-700: #C96A05;
  --main-600: #F58C03;
  --main-500: #FFA70D;
  --main-400: #FFBE34;
  --main-300: #FFDA6E;
  --main-200: #FFEBA7;
  --main-100: #FFF7D3;
  --main-50: #FFFBEC;
}
